<template>
  <div>
    <Header/>
    <section id="cart" class="complete">
      <div class="inner">
        <div class="breadcrumbs txt-white">
          購物車 > 填寫資料與付款 > 訂購完成
        </div>
        <div class="complete_block txt-center">
          <img src="@/assets/img/shopping/icon_done.svg" alt="">
          <br><br>
          <h5 class="txt-white txt-bold">
            訂購失敗
          </h5>
          <br>
          <h6
            class="txt-white txt-bold"
            v-text="''">
            <!-- 您的訂單已成立，您可隨時到站上查詢訂單資訊 -->
            <!-- <span class="txt-underline"> -->
              <!-- WH202006203 -->
            <!-- </span> -->
          </h6>
          <br><br>
          <router-link
            :to="{name: 'MemberOrder'}"
            class="btn btn-orange_white_line" style="width:200px">
            <img src="@/assets/img/icon/icon_next-o.svg" alt="">
            <p>
              我的訂單
            </p>
          </router-link>
        </div>
      </div>
    </section>
    <section id="recommend">
      <!-- 第一個Slider -->
      <!-- <div class="recommend_box box1 swiper-container">
        <div class="swiper-wrapper">
          <a href="#" class="recommend_item swiper-slide">
            <figure
              :style="`background-image: url(${require('@/assets/img/index/banner_1.jpg')});`">
            </figure>
          </a>
        </div>
        <div class="swiper-pagination"></div>
      </div> -->
      <!-- 廣告連結輪播區 -->
      <IndexGoAbroad
        v-if="eventData.length > 0"
        :eventData="eventData"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import IndexGoAbroad from '@/components/index/IndexGoAbroad.vue';
import { mapState, mapMutations } from 'vuex';

import requestApi from '@/lib/http/index';
import '@/assets/scss/cart.scss';

export default {
  name: 'CartComplete',
  props: ['category'],
  components: {
    Header,
    IndexGoAbroad,
  },
  data() {
    return {
      eventData: [],
    };
  },
  methods: {
    ...mapMutations([
      'resetOrderData',
    ]),
    async getLastEvents() {
      this.eventData = [];
      const result = await requestApi('home.getLastEvents');
      result.data.forEach((element) => {
        const item = {
          id: element.id,
          pic: element.cover,
          category: element.category,
          title: element.title,
        };
        this.eventData.push(item);
      });
    },
  },
  computed: {
    ...mapState([
      'isLogin',
    ]),
  },
  mounted() {
    if (!this.isLogin) {
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: '未登入會員',
        confirmButtonText: '確定',
        thenFunc: () => {
          this.$router.push('/');
        },
      });
    } else {
      this.resetOrderData();
      localStorage.removeItem('currentCartType');
      localStorage.removeItem('cartsHasRent');
      localStorage.removeItem('order');
    }
  },
};
</script>
